<template>
    <Preloader v-if="isLoading" />
    <div v-else class="blog-details-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="blog-details-desc">
                        <div class="article-image">
                            <div @click="changeSlug(blog.attributes.slug)"  class="tag">{{
                                blog.attributes.blog_categories.data[0].attributes.name }}</div>
                            <img :src="baseUrl + blog.attributes.image.data.attributes.url" alt="blog-details">
                        </div>
                        <div class="article-content">
                            <div class="entry-meta">
                                <ul>
                                    <li><i class="ri-calendar-2-line"></i>{{ formatDate(blog.attributes.updatedAt) }}</li>
                                    <!-- <li><i class="ri-message-2-line"></i><router-link to="/blog-grid">(4)
                                            Comments</router-link></li> -->
                                </ul>
                            </div>
                            <h4>{{ blog.attributes.title }}
                            </h4>
                            <div v-html="renderedContent"></div>
                        </div>

                        <div class="related-post">
                            <h3 class="title">Related Post</h3>
                            <div class="row justify-content-center">
                                <div class="col-lg-6 col-md-6" v-for="(blog) in relatedBlogs" :key="blog.id">
                                    <div class="single-blog-post">
                                        <div class="image">
                                            <div @click="changeSlug(blog.attributes.slug)" class="d-block">
                                                <img :src="baseUrl + blog.attributes.image.data.attributes.url" alt="blog">
                                            </div>
                                            <div @click="changeSlug(blog.attributes.slug)" class="tag">{{ formatDate(blog.attributes.updatedAt) }}</div>
                                        </div>
                                        <div class="content">
                                            <ul class="meta">
                                                <li><i class="ri-time-line"></i> {{ formatDate(blog.attributes.updatedAt) }}</li>
                                                <!-- <li><i class="ri-message-2-line"></i> <router-link to="/">(0)
                                                        Comment</router-link></li> -->
                                            </ul>
                                            <h3><div @click="changeSlug(blog.attributes.slug)">{{ blog.attributes.title }}</div></h3>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <aside class="widget-area">
                        <div class="widget widget_search">
                            <form class="search-form">
                                <label><input type="search" class="search-field" placeholder="Search..."></label>
                                <button type="submit"><i class="ri-search-2-line"></i></button>
                            </form>
                        </div>
                        <div class="widget widget_meander_software_posts_thumb">
                            <h3 class="widget-title">Popular Posts</h3>
                            <article class="item" v-for="(blog) in popularBlogs.data" :key="blog.id">
                                <div @click="changeSlug(blog.attributes.slug)" class="thumb"><span
                                        class="fullimage cover"
                                        :style="{ backgroundImage: 'url(' + baseUrl + blog.attributes.image.data.attributes.url + ')' }"
                                        role="img"></span></div>
                                <div class="info">
                                    <h4 class="title usmall"><div @click="changeSlug(blog.attributes.slug)">{{ blog.attributes.title
                                    }}</div></h4>
                                    <span class="date"><i class="ri-calendar-2-fill"></i>{{
                                        formatDate(blog.attributes.updatedAt) }}</span>
                                </div>
                            </article>

                        </div>
                        <div class="widget widget_categories">
                            <h3 class="widget-title">Categories</h3>
                            <ul>
                                <li v-for="(category) in categories" :key="category.id">
                                    <router-link :to="{path:'/resources',query:{slug:category.attributes.slug}}" >
                                    <a class="cursor-pointer ">{{ category.attributes.name }}
                                        <!-- <span class="post-count">(2)</span> -->
                                    </a>
                                </router-link>
                                </li>
                                <!-- <li v-for="(category) in categories" :key="category.id">
                                    <a>{{ category.attributes.name }}
                                       
                                    </a>
                                </li> -->
                            </ul>
                        </div>
                        <!-- <div class="widget widget_archive">
                            <h3 class="widget-title">Archives</h3>
                            <ul>
                                <li><router-link to="/blog-right-sidebar">May 2020 (1)</router-link></li>
                                <li><router-link to="/blog-right-sidebar">April 2020 (2)</router-link></li>
                                <li><router-link to="/blog-right-sidebar">June 2020 (3)</router-link></li>
                            </ul>
                        </div>
                        <div class="widget widget_tag_cloud">
                            <h3 class="widget-title">Tags</h3>
                            <div class="tagcloud">
                                <router-link to="/blog-right-sidebar">Advertisment</router-link>
                                <router-link to="/blog-right-sidebar">Business</router-link>
                                <router-link to="/blog-right-sidebar">Life</router-link>
                                <router-link to="/blog-right-sidebar">Lifestyle</router-link>
                                <router-link to="/blog-right-sidebar">Fashion</router-link>
                                <router-link to="/blog-right-sidebar">Ads</router-link>
                                <router-link to="/blog-right-sidebar">Inspiration</router-link>
                                <router-link to="/blog-right-sidebar">Blog</router-link>
                            </div>
                        </div> -->
                    </aside>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Preloader from '../Layout/Preloader.vue';
import MarkdownIt from 'markdown-it';

export default {
    name: 'Details',
    components: {
        Preloader,
    },
    data() {
        return {
            isLoading: true,
            blog: {},
            categories: [],
            popularBlogs: [],
            relatedBlogs: [],
            slug: this.$route.params.slug,
            baseUrl: 'https://content.meander.software',
            md: new MarkdownIt() // Created a new instance of markdown-it

        };
    },
    created: async function () {
        await this.getBlog();
        await this.getCategories();
        await this.fetchPopularPosts();
        this.isLoading = false;
        await this.fetchRelatedPosts(this.blog.attributes.blog_categories.data[0].attributes.name);
    },
    methods: {

        async getBlog() {
            try {
                const response = await axios.get(`${this.$baseUrl}/blogs?filters[slug]=${this.slug}&populate=*`);
                const { data } = response;
                this.blog = data.data[0];
            } catch (error) {
                console.error(error);
            }
        },

        async fetchRelatedPosts(category) {
            const response = await axios.get(`${this.$baseUrl}/blogs?pagination[limit]=2&category=${category}&populate=*`);
            const { data } = response;
            this.relatedBlogs = data.data;
        },
        async getCategories() {
            const response = await axios.get(`${this.$baseUrl}/blog-categories`);
            this.categories = response.data.data;
        },
        async fetchPopularPosts() {
            const response = await axios.get(`${this.$baseUrl}/blogs?pagination[limit]=5&populate=image`);
            this.popularBlogs = response.data;
        },

        formatDate(dateString) {
            const date = new Date(dateString);
            // Then specify how you want your dates to be formatted
            return new Intl.DateTimeFormat('default', { dateStyle: 'long' }).format(date);
        },
        changeSlug(slug) {
      // Simulating slug change
      this.slug = slug;
       this.getBlog();
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // You can change this to 'auto' for instant scrolling
      });
      
    
    }

    },
    computed: {
        renderedContent() {
            if (this.blog) {
                // Assuming your content property is named 'content'
                return this.md.render(this.blog.attributes.content);
            }
            return '';
        }
}
}
</script>