<template>
    <div :class="['navbar-area', { 'is-sticky': isSticky }]">
        <div class="meander_software-nav">
            <div class="container">
                <nav class="navbar navbar-expand-lg navbar-light bg-light">
                    <router-link class="navbar-brand" to="/">
                        <img src="../../assets/images/newlogo.png" alt="logo">
                        <img src="../../assets/images/black-logo.png" alt="logo">
                    </router-link>

                    <div class="navbar-toggler" @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
                        v-bind:class="{ 'active': button_active_state }"
                        v-on:click="button_active_state = !button_active_state">
                        <span class="icon-bar top-bar"></span>
                        <span class="icon-bar middle-bar"></span>
                        <span class="icon-bar bottom-bar"></span>
                    </div>

                    <div class="collapse navbar-collapse" :class="{ show: active }">
                        <ul class="navbar-nav">

                            <li class="nav-item megamenu">
                                <a href="#" class=" nav-link">Apps</a>
                                <ul class="dropdown-menu" v-if="appCategories?.data?.length">
                                    <li class="nav-item">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col" v-for="category in appCategories?.data"
                                                    :key="category.id">
                                                    <h6 class="submenu-title">{{ category.attributes?.name }}</h6>
                                                    <ul class="megamenu-submenu">
                                                        <li v-for="app in category?.attributes?.related_apps?.data"
                                                            :key="app?.id">
                                                            <router-link :to="`/app-clones/${app?.attributes?.slug}`"
                                                                @click="setAppsInLocalStorage(category)"
                                                               v-if="app?.attributes?.isadd" 
                                                                >{{
                                                                    app?.attributes?.name }}</router-link>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <!-- <div class="col">
                                                    <router-link to="/" target="_blank" class="d-block p-0">
                                                        <img src="../../assets/images/navbar.jpg" alt="image">
                                                    </router-link>
                                                </div> -->
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <a href="#" class=" nav-link">Products</a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"  v-for="product in products?.data"  :key="product.id">
                                        <router-link :to="`/products/${product?.attributes?.slug}`" 
                                        @click="setproductsInLocalStorage(product?.attributes?.slug)" class="nav-link">{{ product?.attributes?.name }}</router-link>
                                    </li>
                                   


                                </ul>
                            </li>
                            <!-- <li class="nav-item"><a href="#" class=" nav-link">Products</a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link to="/meander-live" class="nav-link">Meander LIVE</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/meander-one" class="nav-link">Meander ONE</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/doers-online" class="nav-link">Doers ONLINE</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/drawtopic-digital" class="nav-link">Drawtopic  DIGITAL</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/meander-training" class="nav-link">Meander Training</router-link>
                                    </li>

                                </ul>
                            </li> -->
                            <!-- services -->
                            <li class="nav-item">
                                <a href="#" class=" nav-link">Services</a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"  v-for="service in services?.data"  :key="service.id">
                                        <router-link :to="`/services/${service?.attributes?.slug}`" 
                                        @click="setServiceInLocalStorage(service?.attributes?.slug)" class="nav-link">{{ service?.attributes?.name }}</router-link>
                                    </li>
                                   


                                </ul>
                            </li>
                            <!-- industrires -->

                            <li class="nav-item"><a href="#" class=" nav-link">Industries</a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item" v-for="industry in industries?.data" :key="industry.id">
                                        <router-link :to="`/industries/${industry?.attributes?.slug}`"
                                        @click="setIndustriesInLocalStorage(industry)" class="nav-link">{{industry?.attributes?.name }}</router-link>
                                    </li>
                                   
                                    <!--  -->
                                </ul>
                            </li>
                            <!-- technologies -->
                            <li class="nav-item megamenu">
                                <a href="#" class=" nav-link">Technologies</a>
                                <ul class="dropdown-menu" v-if="technology?.data?.length">
                                    <li class="nav-item">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col" v-for="category in technology?.data"
                                                    :key="category.id">
                                                    <h6 class="submenu-title">{{ category.attributes?.name }}</h6>
                                                    <ul class="megamenu-submenu">
                                                        <li v-for="app in category?.attributes?.ms_technologies?.data"
                                                            :key="app?.id">
                                                            <router-link :to="`/technologies/${app?.attributes?.slug}`"
                                                            @click="settechologiesInLocalStorage(category?.attributes?.ms_technologies?.data)" >{{
                                                                    app?.attributes?.name }}</router-link>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <!-- <div class="col">
                                                    <router-link to="/" target="_blank" class="d-block p-0">
                                                        <img src="../../assets/images/navbar.jpg" alt="image">
                                                    </router-link>
                                                </div> -->
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>


                            <!-- <li class="nav-item megamenu">
                                <a href="#" class=" nav-link">Technologies</a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col">
                                                    <h6 class="submenu-title">Web & Backend</h6>
                                                    <ul class="megamenu-submenu">

                                                        <li>
                                                            <router-link to="/technologies">Node & Mongo</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/technologies">PHP & MySQL</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/technologies">Python</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/technologies">.Net Framework</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/technologies">Wordpress & Drupal
                                                                CMS</router-link>
                                                        </li>

                                                    </ul>
                                                </div>
                                                <div class="col">
                                                    <h6 class="submenu-title">Mobile & PWA</h6>
                                                    <ul class="megamenu-submenu">
                                        relatedApps                <li>
                                                            <router-link to="/technologies">Android & Kotlin</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/technologies">iOS and Swift</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/technologies">React Native</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/technologies">Flutter</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/technologies">Angular PWA</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/technologies">Responsive Web
                                                                Apps</router-link>
                                                        </li>


                                                    </ul>
                                                </div>
                                                <div class="col">
                                                    <h6 class="submenu-title">Frameworks</h6>
                                                    <ul class="megamenu-submenu">
                                                        <li>
                                                            <router-link to="/technologies">Django</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/technologies">MEAN</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/technologies">MEVN</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/technologies">MERN</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/technologies">Laravel</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/technologies">AWS Serverless</router-link>
                                                        </li>

                                                    </ul>
                                                </div>
                                                <div class="col">
                                                    <h6 class="submenu-title">3rd Party Integrations</h6>

                                                    <ul class="megamenu-submenu">
                                                        <li>
                                                            <router-link to="/technologies">Sales Force</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/technologies">Payment Gateways</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/technologies">Zoom API
                                                                Integration</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/technologies">Agora Streaming
                                                                Integration</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/technologies">Google APIs</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/technologies">Ticket Support
                                                                System</router-link>
                                                        </li>

                                                    </ul>
                                                </div>

                                                <div class="col">
                                                    <h6 class="submenu-title">NewTech</h6>

                                                    <ul class="megamenu-submenu">
                                                        <li>
                                                            <router-link to="/technologies">Block Chain</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/technologies">Cryptocurrency</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/technologies">Machine Learning</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/technologies">Artificial
                                                                Intelligence</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/technologies">iOT</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/technologies">AWS and Azure
                                                                Solutions</router-link>
                                                        </li>

                                                    </ul>
                                                </div>
                                                <div class="col">
                                                    <router-link to="/" target="_blank" class="d-block p-0">
                                                        <img src="../../assets/images/navbar.jpg" alt="image">
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li> -->


                            <li class="nav-item"><a href="#" class=" nav-link">Company</a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">

                                        <router-link to="/about-meander" class="nav-link">About us</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/success-stories" class="nav-link">Success Stories</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/our-process" class="nav-link">Our Process</router-link>
                                    </li>
                                    <li class="nav-item">

                                        <router-link to="/become-a-partner" class="nav-link">Become a Partner</router-link>
                                    </li>
                                    <li class="nav-item">

                                        <router-link to="/career" class="nav-link">Career</router-link>
                                    </li>

                                </ul>
                            </li>
                            <li class="nav-item">
                                <router-link to="/resources" class="nav-link">Resources</router-link>
                                <!-- <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link to="/blog-custom" class="nav-link">Blog Grid</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/industries" class="nav-link">Blog Right Sidebar</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/blog-left-sidebar" class="nav-link">Blog Left Sidebar</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/" class="nav-link">Blog Details</router-link>
                                    </li>
                                </ul> -->
                            </li>

                        </ul>
                    </div>

                    <div class="others-option">
                        <!-- <router-link to="/contact" class="default-btn">Contact</router-link> -->
                        <button class="default-btn ms-2" @click="openPopup(),contectButton()">Contact</button>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'Navbar',
    props:{
        openPopup:Function
    },
    data() {
        return {
            isSticky: true, //it was false and now temporary true 
            isMenu: false,
            active: false,
            button_active_state: false,
            appCategories: [],
            technology:[],
            services:[],
            industries:[],
            products:[],
            baseUrl: 'https://content.meander.software',
        }
    },
    mounted: async function () {
        await this.getAppCategories();
        await this.getTechnolgies();
        await this.getServices();
        await this.getIndustries();
        await this.getProducts()

        
        
        
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if (scrollPos >= 100) {
                that.isSticky = true
            } else {
                that.isSticky = true //it was false and now temporary true 
            }
        })
    },
    methods: {
        //api functions--------->
          // app

contectButton (){
    fbq('track', 'Contact');
    console.log("contectButton");
     
},

        async getAppCategories() {
            try {
                const response = await axios.get('https://content.meander.software/api/ms-apps-categories', {
  params: {
    populate: 'related_apps.circle_image',
  },
})
                const { data } = response;   
                this.appCategories = data;
                
            } catch (error) {
                console.log(error);
            }
        },
    // products
    async getProducts() {
      try {
        const response = await axios.get(`${this.$baseUrl}/ms-products`);
        const   {data} = await response;
         this.products=data
      } catch (error) {
        console.log(error);  
      }
    },

        // technology
        async getTechnolgies() {
      try {
        const response = await axios.get(`${this.$baseUrl}/ms-technology-categories?populate=ms_technologies`);
        const   {data} = await response;
         this.technology=data
      } catch (error) {
        console.log(error);  
      }
    },

    //services

    async getServices(){
        try {
            const response= await axios.get(`${this.$baseUrl}/ms-services`);
            const {data} = await response
            this.services= data
        } catch (error) {
            console.log(error);    
            }
    },
        


    //industry

    async getIndustries(){
        try {
            const response= await axios.get(`${this.$baseUrl}/ms-industries`);
            const {data} = await response
            this.industries= data
            
        } catch (error) {
            console.log(error);
        }
    },




        //local storage-------->
        setAppsInLocalStorage(newApps) {
            try {
                // console.log(newApps?.attributes?.related_apps?.data[0]?.attributes?.isadd)
                // const filteredApps = newApps?.attributes?.related_apps?.data.filter(app => app?.attributes?.isadd);
                // Remove existing data
                localStorage.removeItem('relatedApps');

                // Save new data
                localStorage.setItem('relatedApps', JSON.stringify(newApps));
            } catch (error) {
                console.log(error);
                console.error('Error setting data in local storage:', error);
            }
        },
        setproductsInLocalStorage(newproducts) {
            try {
                // Remove existing data
                localStorage.removeItem('relatedproducts');

                // Save new data
                localStorage.setItem('relatedproducts', JSON.stringify(newproducts));
            } catch (error) {
                console.log(error);
                console.error('Error setting data in local storage:', error);
            }
        },
        settechologiesInLocalStorage(newtechologies) {
            try {
                // Remove existing data
                localStorage.removeItem('relatedTechologies');

                // Save new data
                localStorage.setItem('relatedTechologies', JSON.stringify(newtechologies));
            } catch (error) {
                console.log(error);
                console.error('Error setting data in local storage:', error);
            }
        },
        setIndustriesInLocalStorage(newIndustries) {
            try {
                // Remove existing data
                localStorage.removeItem('newIndustries');

                // Save new data
                localStorage.setItem('newIndustries', JSON.stringify(newIndustries));
            } catch (error) {
                console.log(error);
                console.error('Error setting data in local storage:', error);
            }
        },
        setServiceInLocalStorage(newservice) {
            try {
                // Remove existing data
                localStorage.removeItem('newservice');

                // Save new data
                localStorage.setItem('newservice', JSON.stringify(newservice));
            } catch (error) {
                console.log(error);
                console.error('Error setting data in local storage:', error);
            }
        },


    }
}
</script>