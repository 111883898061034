<template>
    <div class="app-download-area  pb-5">
        <div class="container">
            <div class="app-download-inner bg-gray">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="app-download-content">
                            <span class="sub-title text-uppercase  ">{{this.data[0]?.attributes?.ms_apps_schedule_demos?.data[0]?.attributes?.title ||"Schedule Demo"}}</span>
                            <h2>{{this.data[0]?.attributes?.ms_apps_schedule_demos?.data[0]?.attributes?.subtitle ||"Experience Our Product in Real Time"}}</h2>
                            <p>{{this.data[0]?.attributes?.ms_apps_schedule_demos?.data[0]?.attributes?.description ||"Unlock the full potential of our solution with a personalized demo. Schedule your session today and discover how our product can transform your experience."}}</p>
                            <!-- <div class="btn-box">
                                <a href="#" class="playstore-btn" target="_blank">
                                    <img src="../../assets/images/play-store.png" alt="image">
                                    Get It On
                                    <span>Google Play</span>
                                </a>
                                <a href="#" class="applestore-btn" target="_blank">
                                    <img src="../../assets/images/apple-store.png" alt="image">
                                    Download on the
                                    <span>Apple Store</span>
                                </a>
                            </div> -->
                            <!-- <div class="btn-box mt-5 ">
                            <a   @click="openCalendly" class="default-btn text-uppercase cursor-pointer">Schedule Demo</a>
                            <a href="#" class="link-btn">See All Features</a>
                        </div> -->
                        <button @click="openCalendly" class="default-btn">Schedule Demo</button>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="app-download-image" data-aos="fade-up">
                            <img v-if="this.data && this.data[0]?.attributes?.ms_apps_schedule_demos?.data[0]?.attributes?.image?.data?.attributes?.url" 
     :src="'https://content.meander.software' + this.data[0]?.attributes?.ms_apps_schedule_demos?.data[0]?.attributes?.image?.data?.attributes?.url" 
     alt="image">
                            <img v-else src="../../assets/images/app/app-img4.png" alt="app-img">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppDownload',
    props: {
 
 data: {
   type: Array,
   required: true
 }
},
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
}
}
</script>