<template v-if="faqData">
    <div class="faq-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-12">
                    <div class="faq-sidebar">
                        <ul>
                            <li v-for="faq_category in faqData" :key="faq_category.id" @click="selectedData=faq_category">
                                <a :class="{'active':selectedData?.id==faq_category?.id}">
                                    <span>{{faq_category?.attributes?.name}}</span>
                                </a>
                            </li>
                           
                        </ul>
                    </div>
                </div>
                <div class="col-lg-8 col-md-12">
                    <div class="faq-accordion">
                        <accordion>
                            <accordion-item v-for="questionData in selectedData?.attributes?.ms_faq_questions?.data">
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        {{ questionData?.attributes?.question }}
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <div class="accordion-body" v-html="questionData?.attributes?.answer">
                                       
                                    </div>
                                </template>
                            </accordion-item>
                          
                        </accordion>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from "../Common/Accordion";
import AccordionItem from "../Common/AccordionItem";
import axios from "axios";
export default {
    name: 'Faq',
    components: {
        Accordion,
        AccordionItem,
    },
    data() {
        return {
            baseUrl: 'https://content.meander.software',
            faqData: [],
            selectedData:{},
            isLoading: false
        }
    },  async mounted() {
    // await this.getScreens();
    await this.getData();

  },
    methods: {
        async getData() {
            try {
                // Fetch best features data from Strapi API
                // filters[slug][$eq]=${slug}  // query for filter/get data using slug
                const response = await axios.get(`${this.$baseUrl}/ms-faq-categories?populate=*`);
                const { data      } = response
                this.faqData = data.data;
                this.selectedData = this.faqData[0]
            } catch (error) {
                this.faqData = []
            }
        }

    },
}
</script>