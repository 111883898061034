<template>
    <Preloader v-if="isLoading" />
    <div class="about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <span class="sub-title text-uppercase  ">About us
</span>
                        <h2>Introducing Doers Online - Your Complete CRM Solution
</h2>
                        <p>Elevate your customer relationship management with Doers CRM software. Simplify, streamline, and optimize your business processes for enhanced growth and success.
</p>
                        <div class="features-text">
                            <h6>Our App</h6>
                            <p> Experience the power of Doers Online, the comprehensive CRM software that empowers businesses to manage customer relationships effectively, streamline sales processes, and drive growth.</p>
                        </div>
                        <div class="features-text">
                            <h6>Our Mission</h6>
                            <p>At Doers Online, our mission is to revolutionize customer relationship management by providing a user-friendly, feature-rich CRM solution that helps businesses elevate their sales and service, nurture valuable customer connections, and achieve remarkable success.</p>
                        </div>
                        <div class="btn-box">

                            <button @click="openCalendly" class="default-btn">Schedule Demo</button>
                            <!-- <router-link to="/features-one" class="link-btn">See All Features</router-link> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-img">
                        <img src="../../assets/images/app/app-img8.png" data-aos="fade-up" alt="about">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Preloader from '../Layout/Preloader.vue'
export default {
    name: 'AboutApp',
    components: {
        Preloader
    },       
    data() {
        return {
            isLoading: true,
            baseUrl: 'https://content.meander.software',
            aboutUs: Object
        }
    },
    methods: {
        openCalendly() {
           // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
        },
        async getAboutUs() {
            try {
                // Fetch abou us data from Strapi API
                const response = await axios.get(`${this.$baseUrl}/ms-about-uses`);
                const { data      } = response;
                this.aboutUs = data['data'][0];
            } catch (error) {
                this.aboutUs=new Object();
            }
        },

    },
    mounted: async function () {
        await this.getAboutUs();
        this.isLoading = false;
    }
}
</script>