<template>
  <FormModal v-if="showPopup" :closePopup="closePopup" />

  <div>

    <Navbar @click="handleNavbarLinkClick" :openPopup="openPopup" />
    <PageTitle :data='ApiData' />
    <ChallengesAndTrack :data='ApiData' />
    <PartnerTwo :data='ApiData' />
    <CustomFeatures :data="ApiData" />
    <BestFeaturesEver :data="ApiData" />
    <SoftwareIntegrations :data="ApiData" />
    <AppScreens :data="ApiscreenData" />
    <ChallengesAndTrackDark :data="ApiData" />
    <!-- <CustomIntegration :data="ApiData" /> -->
    <Faq />
    <Partner />
    <!-- <FeedbackTwo /> -->
    <TechnolgiesReview />

    <!-- <About />
        <Funfacts />
        <Stats />
        <SoftwareIntegrations />
        <OurTeam />
        <CustomerReviews class="pt-0" />
        <Partner class="bg-f9f9f9" /> -->
    <FooterStyleFour />

  </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
// import PageTitle from '../Common/PageTitle'
import ChallengesAndTrack from '../AboutTwo/ChallengesAndTrack'
import Partner from '../Common/Partner'
import CustomFeatures from "../FeaturesTwo/TechnologiesFeatures"
import BestFeaturesEver from "../FeaturesOne/TechnologiesBestFeatures"
import SoftwareIntegrations from "../AboutOne/TechnologySoftware"
import AppScreens from "../HomeOne/TechnologiesScreens";
import ChallengesAndTrackDark from "../AboutOne/TechnologiesChallengesAndTrack"
import Faq from "../Faq/Faq"
import PartnerTwo from "../Common/TechnologiesUseCompany"
import FooterStyleFour from '../Layout/FooterStyleFour'





// import About from '../AboutOne/About'
// import Funfacts from '../AboutOne/Funfacts'
// import Stats from '../AboutOne/Stats'
// import OurTeam from '../AboutOne/OurTeam'
// import CustomerReviews from '../Common/CustomerReviews'
import axios from 'axios'
import TechnolgiesReview from './TechnolgiesReview'
import PageTitle from './Technology/PageTitle.vue'
import FormModal from '../HomeThree/FormModal.vue'

export default {
  name: 'TechnologiesClone',
  components: {
    Navbar,
    PageTitle,
    ChallengesAndTrack,
    Partner,
    CustomFeatures,
    BestFeaturesEver,
    SoftwareIntegrations,
    AppScreens,
    ChallengesAndTrackDark,
    Faq,
    PartnerTwo,
    // About,
    // Funfacts,
    // Stats,
    // SoftwareIntegrations,
    // OurTeam,
    // CustomerReviews,
    // Partner,
    FooterStyleFour,
    TechnolgiesReview,
    FormModal
},
  data() {
    return {
      baseUrl: 'https://content.meander.software',
      ApiData: [] || {} || Array,
      ApiscreenData: {},
      isLoading: false,
      title: '',
      showPopup:false

    }
  },
  async mounted() {
    await this.getData();
    await this.getData22();

  },
  methods: {
    openPopup(){
     this.showPopup  = true
    },
    closePopup(){
     this.showPopup= false
    },
    async getData() {
      const slug = this.$route.params.slug;
      try {
        const response = await axios.get(`${this.$baseUrl}/ms-technologies?filters[slug][$eq]=${slug}&populate=*`);
        const { data } = response
        this.ApiData = data.data;
        const description = this.ApiData[0].attributes.ms_common_meta_tags?.data[0]?.attributes?.description;         
        this.title = this.ApiData[0]?.attributes?.ms_common_meta_tags?.data[0]?.attributes?.title
        document.title = this.title || "technologies"
        const metaDescription = document.querySelector('meta[name="description"]');

        if (metaDescription) {
          // If the <meta> tag exists, update its content
          metaDescription.content = description;
        } else {
          // If the <meta> tag doesn't exist, create a new one and append it to the <head> section
          const newMetaTag = document.createElement('meta');
          newMetaTag.name = 'description';
          newMetaTag.content = description;
          document.head.appendChild(newMetaTag);
        }
      } catch (error) {
      console.log(error);
      }
    },
    async getData22() {
      const slug = this.$route.params.slug;
      try {
        const response = await axios.get(`${this.$baseUrl}/ms-technologies?filters[slug][$eq]=${slug}&populate=ms_technologies_screendesgins.screenShots`);
        const { data } = response
        this.ApiscreenData = data.data;

      } catch (error) {
        console.log(error);
      }
    },
    async handleNavbarLinkClick(event) {
      this.isLoading = true
      if (event?.target?.href?.includes('technologies')) {
        await this.getData();

      }
      this.isLoading = false

    },
  },

}
</script>