<template>
    <div class="trusted-by" v-if="carouselItems?.length">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-12">
                <span class="title">Our Trending Apps:</span>
            </div>
            <div class="col-lg-9 col-md-12">
                <div class="trusted-by-slides">
                    <!-- <carousel :autoplay="5000" :settings='settings' :breakpoints='breakpoints'>
                    <slide v-for="slide in carouselItems" :key="slide?.id">
                        <div class="item" v-if="slide?.attributes?.logo?.data?.attributes?.url">
                            <img :src="baseUrl + slide?.attributes?.logo?.data?.attributes?.url" alt="image">
                        </div>
                    </slide>
                </carousel> -->
                    <carousel
                        :autoplay="5000"
                        :settings='settings'
                        :breakpoints='breakpoints'
                    >
                        <slide 
                            v-for="slide in carouselItems" 
                            :key="slide.id"
                        >
                            <div class="item">
                                <img :src="slide.image" alt="partner">
                            </div>
                        </slide>

                        <template #addons>
                        </template>
                    </carousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue';
import { Carousel, Slide  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'TrustedBy',
    components: {
        Carousel,
        Slide,
    },
    data: () => ({
        baseUrl: 'https://content.meander.software',
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/trendingapps/a.png'),
            },
            {
                id: 2,
                image: require('../../assets/images/trendingapps/b.png'),
            },
            {
                id: 3,
                image: require('../../assets/images/trendingapps/d.png'),
            },
            {
                id: 4,
                image: require('../../assets/images/trendingapps/e.png'),
            },
            {
                id: 5,
                image: require('../../assets/images/trendingapps/f.png'),
            },
            {
                id: 6,
                image: require('../../assets/images/trendingapps/g.png'),
            },
            {
                id: 7,
                image: require('../../assets/images/trendingapps/h.png'),
            },
            {
                id: 8,
                image: require('../../assets/images/trendingapps/i.png'),
            },
            {
                id: 9,
                image: require('../../assets/images/trendingapps/j.png'),
            },
            {
                id: 10,
                image: require('../../assets/images/trendingapps/k.png'),
            },
            {
                id: 11,
                image: require('../../assets/images/trendingapps/l.png'),
            },
            {
                id: 12,
                image: require('../../assets/images/trendingapps/m.png'),
            },
            {
                id: 13,
                image: require('../../assets/images/trendingapps/n.png'),
            },
            {
                id: 14,
                image: require('../../assets/images/trendingapps/o.png'),
            },
            {
                id: 15,
                image: require('../../assets/images/trendingapps/p.png'),
            },

        ],
        breakpoints: {
            0: {
                itemsToShow: 2,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 4,
                snapAlign: 'center',
            },
        },
        // carouselItems:[]
    }),
    // mounted() {
    //     this.getData();
    // },
    // methods: {
    //     async getData() {
    //         try {
    //             const response = await axios.get(`${this.$baseUrl}/ms-partners?populate=*`);
    //             const { data      } = response
    //             this.carouselItems = data?.data;
    //         } catch (error) {
    //             this.carouselItems = []
    //         }
    //     },
    // }
})
</script>