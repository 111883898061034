<template>
    <div class="partner-area pt-100 pb-50" v-if="carouselItems?.length">
        <div class="container">
            <div class="section-title">
                <!-- <span class="sub-title text-uppercase  ">CLIENT REVIEWS</span> -->
                <h2>Empowering Success with Partners</h2>
            </div>
           
            <div class="partner-slides">
                <carousel :autoplay="1000" :settings='settings' :breakpoints='breakpoints'>
                    <slide v-for="slide in carouselItems" :key="slide?.id">
                        <div class="partner-item mx-1" v-if="slide?.attributes?.logo?.data?.attributes?.url">
                            <img :src="baseUrl + slide?.attributes?.logo?.data?.attributes?.url" alt="image">
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide } from 'vue3-carousel';
import axios from "axios";
import 'vue3-carousel/dist/carousel.css';
export default defineComponent({
    name: 'Partner',
    components: {
        Carousel,
        Slide,
    },
    data: () => ({
        baseUrl: 'https://content.meander.software',
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [],
        breakpoints: {
            0: {
                itemsToShow: 2,
                snapAlign: 'left',
            },
            576: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 5,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 5,
                snapAlign: 'center',
            },
        },
    }),
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            try {
                const response = await axios.get(`${this.$baseUrl}/ms-partners?populate=*`);
                const { data      } = response
                this.carouselItems = data?.data;
            } catch (error) {
                this.carouselItems = []
            }
        },
    }
})
</script>