<template>
  <FormModal v-if="showPopup" :closePopup="closePopup" />

    <div>
        <Navbar @click="handleNavbarLinkClick"  :openPopup="openPopup"/>
        <AboutService   :data="ApiData"/>
        <offeringFeatures   :data="ApiData" />
        <ServiceTeam :data="techData"/>        
        <AppDownload   :data="ApiData" />
        <!-- <Feedback   :data="ApiData"/> -->
        <IndustryWeServe/>
        <Funfacts/>
        <!-- <Funfacts   :data="ApiData" /> -->
        <IncludedBestFeatures   :data="ApiData" />       
        <BestFeaturesEver   :data="ApiData" />
        <Blog />
        <SoftwareIntegrations    :data="ApiData"/>
        <Partner />
        <ServiceReviews/> 
        <FooterStyleFour />
    </div>
</template>

<script>
import Navbar from '../../Layout/Navbar'
import AboutService from  "./ServiceAbout.vue"
import Partner from '../../Common/Partner' 
import AppDownload from "./ServiceScheduleDemo"
import Feedback from "./ServiceFeedback"
// import Funfacts from "./ServiceStats.vue"
import offeringFeatures from "./ServiceOffering.vue"
import IncludedBestFeatures from './ServiceBenfeit'
 import BestFeaturesEver from './ServiceWeDoWhatOffer.vue'
 import Blog from "../../BlogOne/Blog"
import SoftwareIntegrations from "./ServiceAgileMethodology" 
 import FooterStyleFour from '../../Layout/FooterStyleFour'
import axios from 'axios'
import ServiceTeam from './ServiceTeam'
import ServiceReviews from './ServiceReviews.vue'
import FormModal from '../../HomeThree/FormModal.vue'
import IndustryWeServe from '../../HomeOne/IndustryWeServe.vue'
// import Funfacts from "../AboutOne/Funfacts.vue";

import Funfacts from '../../AboutOne/Funfacts.vue'
export default {
    name: 'ServicesPageClone',
    components: {
    Navbar,
    AboutService,
    IncludedBestFeatures,
    Partner,
    AppDownload,
    Feedback,
    offeringFeatures,
    Funfacts,
    BestFeaturesEver,
    Blog,
    SoftwareIntegrations,
    FooterStyleFour,
    ServiceTeam,
    ServiceReviews,
    FormModal,
    IndustryWeServe
},
    data() {
    return {
      baseUrl: 'https://content.meander.software',
      ApiData:[],
      techData:[],
      isLoading: false,
      showPopup:false

    }
  },
  async mounted() {
    await this.getData();
    await this.getData2();


  },
  methods: {
    openPopup(){
      this.showPopup= true
    },
    closePopup(){
      this.showPopup= false
    },

    async getData() {
      const slug = this.$route.params.slug;
      try {
        const response = await axios.get(`${this.$baseUrl}/ms-services?filters[slug][$eq]=${slug}&populate=*`);
        const { data  } = response
        this.ApiData = data.data;
        const description = this.ApiData[0].attributes.ms_common_meta_tags?.data[0]?.attributes?.description;         
        this.title = this.ApiData[0]?.attributes?.ms_common_meta_tags?.data[0]?.attributes?.title
        document.title = this.title || "services"
        const metaDescription = document.querySelector('meta[name="description"]');

        if (metaDescription) {
          // If the <meta> tag exists, update its content
          metaDescription.content = description;
        } else {
          // If the <meta> tag doesn't exist, create a new one and append it to the <head> section
          const newMetaTag = document.createElement('meta');
          newMetaTag.name = 'description';
          newMetaTag.content = description;
          document.head.appendChild(newMetaTag);
        }

      } catch (error) {
        console.log(error);
      }
    },
    async getData2() {
      const slug = this.$route.params.slug;
      try {
        const response = await axios.get(`${this.$baseUrl}/ms-services?filters[slug][$eq]=${slug}&populate=ms_services_technologies_experts.image,ms_services_client_technologies.technologies`);
        const { data  } = response
        this.techData = data.data;

      } catch (error) {
        console.log(error);
      }
    },
    async handleNavbarLinkClick(event) {
      this.isLoading = true
      if (event?.target?.href?.includes('services')) {
        await this.getData();
      }
      
      this.isLoading = false
    },
  },
}
</script>