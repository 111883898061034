<template>
    <Preloader v-if="isLoading" />
    <div class="about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <span class="sub-title text-uppercase  ">#About us
</span>
                        <h2>Revolutionizing API Integration: Unleash the Power of Meander One
</h2>
                        <!-- <p>{{ aboutUs?.attributes?.description }}</p> -->
                        <div class="features-text">
                            <h6>Our App</h6>
                            <p>Experience the Power of Meander One, the Ultimate API Integration Platform. Streamline your development process, unlock new functionalities, and accelerate your projects with ease.
</p>
                        </div>
                        <div class="features-text">
                            <h6>Our Mission</h6>
                            <p>Revolutionizing API Integration by providing a seamless and comprehensive platform that simplifies the process of connecting and utilizing diverse APIs. We are dedicated to empowering developers and businesses to harness the power of APIs, drive innovation, and achieve their goals.</p>
                        </div>
                        <div class="btn-box">

                            <button @click="openCalendly" class="default-btn">Schedule Demo</button>
                            <!-- <router-link to="/features-one" class="link-btn">See All Features</router-link> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-img">
                        <img src="../../assets/images/app/app-img8.png" data-aos="fade-up" alt="about">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Preloader from '../Layout/Preloader.vue'
export default {
    name: 'About',
    components: {
        Preloader
    },       
    data() {
        return {
            isLoading: true,
            baseUrl: 'https://content.meander.software',
            aboutUs: Object
        }
    },
    methods: {
        openCalendly() {
           // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
        },
        async getAboutUs() {
            try {
                // Fetch abou us data from Strapi API
                const response = await axios.get(`${this.$baseUrl}/ms-about-uses`);
                const { data      } = response;
                this.aboutUs = data['data'][0];
            } catch (error) {
                this.aboutUs=new Object();
            }
        },

    },
    mounted: async function () {
        await this.getAboutUs();
        this.isLoading = false;
    }
}
</script>