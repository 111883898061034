<!-- <template>
    <div class="partner-area pt-100 pb-50"  v-if="carouselItems?.length" style="padding-top: 5rem !important; padding-bottom: 5rem !important;"> 
        <div class="container">
            <div class="partner-title">
               <h3>
                OUR BLOGS
               </h3>
            </div>
            <div class="partner-slides">
                <carousel :autoplay="2000" :settings='settings' :breakpoints='breakpoints'>
                    <slide v-for="slide in carouselItems" :key="slide?.id">
                        <router-link :to="`/resource-details/${slide.attributes.slug}`"  class="d-block">
                        <div class="partner-item mx-3" v-if="slide?.attributes?.image?.data?.attributes?.url">
                           <div>

                               <img :src="'https://content.meander.software' + slide?.attributes?.image?.data?.attributes?.url" alt="image">
                           </div>
                           <div>
                            <h6>
                                {{slide?.attributes?.title}}
                            </h6>
                           </div>
                        </div></router-link>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide } from 'vue3-carousel';
import axios from "axios";
import 'vue3-carousel/dist/carousel.css';
export default defineComponent({
    name: 'Partner',
    components: {
        Carousel,
        Slide,
    },
    data: () => ({
        baseUrl: 'https://content.meander.software',
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'left',
            },
            576: {
                itemsToShow: 3,
                snapAlign: 'left',
            },
            768: {
                itemsToShow: 3,
                snapAlign: 'left',
            },
            992: {
                itemsToShow: 3,
                snapAlign: 'left',
            },
            1200: {
                itemsToShow: 3,
                snapAlign: 'left',
            },
        },
    }),
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            try {
                const response = await axios.get(`${this.$baseUrl}/blogs?populate=*`);
                const { data } = response
                this.carouselItems = data?.data.slice(0,10);
            } catch (error) {
                this.carouselItems = []
            }
        },
    }
})
</script> -->









<template>
    <div class="feedback-area pt-100" v-if="this.jobOpennings?.length">
        <div class="container">
            <div class="section-title">
                <span class="sub-title text-uppercase  ">Current Job Openings</span>
                <h2>Opportunity Knocks: Explore Our Current Job Adventures</h2>
            </div>
            <div class="feedback-swiper-slides">
                <carousel
                    :autoplay="5000"
                    :settings='settings'
                >
                    <slide 
                        v-for="slide in jobOpennings" 
                        :key="slide?.id"
                    >
                        <div class="single-feedback-item">
                            <div class="client-info">
                                <img :src="`https://content.meander.software/${slide?.attributes?.logo?.data?.attributes?.url}`" alt="user">
                                <div class="title">
                                    <h3>{{slide?.attributes?.title}}</h3>
                                    <!-- <span>{{slide.position}}</span> -->
                                </div>
                            </div>
                            <p>{{slide?.attributes?.description}}</p>
                            <div class="rating">
                                <!-- <h5>{{slide.category}}</h5> -->
                                <router-link to="/contact" class="nav-link">  <button   class="default-btn">Contact Us</button>   </router-link>

                            </div>
                        </div>
                    </slide>

                    <template #addons>
                        <navigation>
                            <template #next data-aos="fade-right">
                                <i class='ri-arrow-right-s-line'></i>
                            </template>
                            <template #prev data-aos="fade-left">
                                <i class='ri-arrow-left-s-line'></i>
                            </template>
                        </navigation>
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'FeedbackTwo',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
      baseUrl: 'https://content.meander.software',
      jobOpennings:[],
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
        {
                id: 1,
                image: require('../../assets/images/user/user1.jpg'),
                name: 'Deanna Hodges',
                position: 'Client since 2014',
                description: '"Impressed by Meander Software dedication, creativity, and attention to detail. They deliver high-quality solutions with speed and agility."',
                category: 'Theme Customization',
            },
            {
                id: 2,
                image: require('../../assets/images/user/user2.jpg'),
                name: 'Londynn Vargas',
                position: 'Client since 2016',
                description: '"Meander Software stands out with their skill, efficiency, and customer-focused approach. Highly regarded in the industry."',
                category: 'Customer Support',
            },
            {
                id: 3,
                image: require('../../assets/images/user/user3.jpg'),
                name: 'James Andy',
                position: 'Client since 2020',
                description: '"Meander Software is a standout digital agency in our area, offering personalized attention and exceptional expertise."',
                category: 'Responsive Design',
            },
            {
                id: 4,
                image: require('../../assets/images/user/user4.jpg'),
                name: 'David Warner',
                position: 'Client since 2015',
                description: '"Choosing Meander Software was the best decision for our digital initiatives. Their tailored solutions and flexibility have exceeded our expectations."',
                category: 'Design Quality',
            },
        ],
    }),
    methods:{
        async getData2() {
     
      try {
        const response = await axios.get(`${this.$baseUrl}/ms-job-opennings?populate=*`);
        const { data  } = response
        this.jobOpennings = data.data;
console.log(this.jobOpennings,'====><><><><><><=====');
      } catch (error) {
        console.log(error);
      }
    },
    },
    async mounted() {
  
    await this.getData2();


  },
})
</script>